import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsStaggered } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

type Props = {
  onClick: () => void;
  className?: string;
};

const LeftButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <div className={styles.container(className)}>
      <button
        type="button"
        className={styles.btn.container}
        aria-controls="mobile-menu"
        aria-expanded="false"
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={faBarsStaggered}
          className={styles.btn.icon}
          size="lg"
        />
      </button>
    </div>
  );
};

export default LeftButton;

const styles = {
  container: (className: string) => classNames('flex items-center', className),
  btn: {
    container: classNames(
      'bg-white inline-flex items-center justify-center p-2 text-gray-400',
      'scale-x hover:scale-x-[-1]'
    ),
    icon: 'fa-fw text-[#161E3A]',
  },
};
