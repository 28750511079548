import Link from 'next/link';
import { appRoutes } from 'config';
import { useAuthentication } from 'hooks/useAuthentication';
import UserButton from '../../elements/UserButton';
import { useProStatus } from 'networking/revenueCat/hooks/useProStatus';
import LeftButton from './Button/LeftButton';
import RightButton from './Button/RightButton';
import {
  openBoxSidebar,
  openCollectionSidebar,
  openSettingsSidebar,
} from 'xstate/services/SidebarService';
import { useRouter } from 'next/router';

type Props = {
  isTransparent?: boolean;
};

const Header = ({ isTransparent = false }: Props): JSX.Element => {
  const { authenticatedUser } = useAuthentication();
  const { isPro } = useProStatus();
  const router = useRouter();

  const isDashboard = router.pathname.startsWith(appRoutes.MEMORY_BOX);
  const isSettings = router.pathname.startsWith(appRoutes.SETTINGS);

  return (
    <nav
      className={
        isTransparent
          ? 'bg-white fixed top-0 w-full border-b border-gray-200 z-10 shadow-sm'
          : 'bg-white fixed top-0 w-full border-b border-gray-200 z-10 shadow-sm'
      }
    >
      <div className="3xl:max-w-8xl 3xl:mx-auto px-4 lg:px-6">
        <div className="flex justify-between h-16">
          {/* Mobile - Left Toggle Menu Button */}
          {isDashboard && (
            <LeftButton className="lg:hidden" onClick={openBoxSidebar} />
          )}

          {isSettings && (
            <LeftButton className="lg:hidden" onClick={openSettingsSidebar} />
          )}

          {/* Logo */}
          <Link href={appRoutes.MEMORY_BOX} className="flex items-center">
            <img
              className="block h-10 w-auto"
              src="/logotype-light.svg"
              alt="MindFeed"
            />
          </Link>

          <div className="hidden sm:ml-6 lg:flex sm:items-center">
            <UserButton user={authenticatedUser} isPro={isPro} />
          </div>

          {/* Mobile - Right Toggle Menu Button */}
          {isDashboard && (
            <RightButton
              className="lg:hidden"
              onClick={openCollectionSidebar}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
