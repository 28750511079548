import { useWindowSize, WindowSize } from 'hooks';
import Image from 'next/image';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import LinkBtn from 'components/elements/LinkBtn';
import { appRoutes } from 'config';
import useLottie from 'hooks/useLottie';
import { reportUserClickedOnEvent } from 'utils/analytics/segment';
import Tooltip from 'elements/Tooltip';
import { TooltipDirection } from 'elements/Tooltip/Tooltip';
import { useAuthentication } from 'hooks/useAuthentication';
import { useEffect } from 'react';
import Router from 'next/router';

const Home = (): JSX.Element => {
  const size = useWindowSize();
  const { authenticatedUser } = useAuthentication();
  const onboardingOneLottieRef = useLottie('/lottie/onboarding-1.json');
  const onboardingTwoLottieRef = useLottie('/lottie/onboarding-2.json');

  const disableParallax = (size: WindowSize): boolean => {
    return size.width <= 1200;
  };

  useEffect(() => {
    if (authenticatedUser) {
      Router.push(appRoutes.MEMORY_BOX);
    }
  }, [authenticatedUser]);

  return (
    <ParallaxProvider>
      <div className="p-0 flex">
        <div className="mx-auto my-12">
          {/* Part I */}
          <div className="sm:pt-8 sm:pt-12 lg:relative lg:py-48">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
              <div>
                <div className="mt-10">
                  <div className="mt-6 sm:max-w-xl">
                    <Parallax
                      translateY={[20, -20]}
                      disabled={disableParallax(size)}
                    >
                      <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl text-center tracking-normal">
                        <span className="block mb-1">Want to Remember</span>
                        <span className="block mt-2">Stuff?</span>
                      </h1>
                    </Parallax>
                    <div className="mt-6 text-xl text-gray-500 text-center leading-9">
                      Build your personal <b>Memory Box</b> - save your favorite
                      memos, quotes, and pretty much
                      <Tooltip
                        text="That's our mission! 💪"
                        style="inline"
                        direction={TooltipDirection.BottomRight}
                      >
                        &nbsp;
                        <span className="underline underline-offset-4 decoration-dotted">
                          everything
                        </span>
                      </Tooltip>
                      .
                    </div>
                  </div>

                  <div className="mt-12 text-center">
                    <LinkBtn
                      href={appRoutes.LOGIN}
                      text="Get Started"
                      onClick={() =>
                        reportUserClickedOnEvent('Get Started', 'Homepage Hero')
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:mx-auto sm:max-w-3xl sm:px-6 hidden lg:block">
              <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div className="hidden sm:block">
                  <div className="absolute inset-y-0 left-1/2 bg-gray-50 rounded-3xl lg:left-80 lg:right-0"></div>
                  <svg
                    className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                    width="404"
                    height="392"
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="404"
                      height="392"
                      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                  </svg>
                </div>
                <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                  <Parallax
                    translateY={[80, -40]}
                    disabled={disableParallax(size)}
                  >
                    <Image
                      className="w-full lg:h-full lg:w-auto lg:max-w-none"
                      src={'/images/mindfeed-app-mockup.png'}
                      alt="MindFeed App Mockup"
                      priority={true}
                      width={582}
                      height={572}
                    />
                  </Parallax>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-20" />

          {/* Part II */}
          <div className="relative mt-20">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
              <svg
                className="absolute top-8 -ml-3 lg:-left-8 lg:right-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
              <div className="relative sm:py-16 lg:py-0">
                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                  <Parallax
                    translateY={[-40, 0]}
                    disabled={disableParallax(size)}
                  >
                    <div
                      ref={onboardingOneLottieRef}
                      className="w-[449px] h-[367px] mx-auto"
                    />
                  </Parallax>
                </div>
              </div>

              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                <Parallax
                  translateY={[20, -10]}
                  disabled={disableParallax(size)}
                >
                  <div className="pt-12 sm:pt-16 lg:pt-20">
                    <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl tracking-normal">
                      Welcome to MindFeed
                    </h2>
                    <div className="mt-6 text-gray-500 space-y-6">
                      <p className="text-lg">
                        There is a lot of information to digest every day.
                      </p>
                      <p className="text-lg">
                        MindFeed lets you create your personal <b>memory box</b>{' '}
                        so you can send memos, quotes, images, photos, and
                        pretty much everything you want to remember.
                      </p>
                      <p className="text-lg leading-7">
                        Have fun building your personal memory box!
                      </p>
                    </div>
                  </div>
                </Parallax>
              </div>
            </div>
          </div>

          {/* Part III */}
          <div className="mt-8 md:mt-20 xl:mt-32">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <div className="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center mt-20">
                <Parallax
                  translateY={[-20, 10]}
                  disabled={disableParallax(size)}
                >
                  <div>
                    <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl tracking-normal">
                      Remember Everything You Learn.
                    </h2>
                    <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                      By focusing on short, digestible content, MindFeed helps
                      you increase memory retention of the information that
                      matters to you.
                    </p>
                    <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                      The more often you see this content, the more likely you
                      are to remember it.
                    </p>
                  </div>
                </Parallax>
                <Parallax
                  translateY={[0, -40]}
                  disabled={disableParallax(size)}
                >
                  <div
                    ref={onboardingTwoLottieRef}
                    className="w-[449px] h-[367px] mx-auto"
                  />
                </Parallax>
              </div>
            </div>
          </div>

          {/* CTA Section */}
          <div className="relative mt-24 sm:mt-32 sm:py-16">
            <div aria-hidden="true" className="hidden sm:block">
              <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
              <svg
                className="absolute top-8 left-1/2 -ml-3"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
                />
              </svg>
            </div>
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="relative rounded-2xl px-6 py-10 bg-gradient-to-br from-mf-dark-blue to-mf-blue overflow-hidden shadow-xl sm:px-12 sm:py-20">
                <div className="relative">
                  <div className="sm:text-center">
                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl tracking-normal">
                      Remembering valuable content is hard.
                    </h2>
                    <p className="mt-6 mx-auto max-w-2xl text-lg text-blue-100">
                      Join MindFeed and Outsource Your Memory!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default Home;
