import Head from 'next/head';
import React from 'react';
import { useRouter } from 'next/router';
import Header from 'modules/Header';
import VisitorHeader from 'modules/VisitorHeader';
import Footer from 'modules/Footer';
import NoSSRWrapper from 'components/hocs/NoSSRWrapper';
import { BackgroundColor } from 'utils/ColorUtils';
import { useAuthentication } from 'hooks/useAuthentication';

type Props = {
  children: React.ReactNode;
  backgroundColor?: BackgroundColor;
  isHeaderHidden?: boolean;
  isHeaderTransparent?: boolean;
  isFooterHidden?: boolean;
  isFooterTransparent?: boolean;
};

const hasSSREnabled = (): boolean => {
  const { pathname } = useRouter();

  const enabledSSRPaths = ['/', '/privacy-policy', 'terms-of-service'];

  return enabledSSRPaths.includes(pathname);
};

const Default = ({
  children,
  backgroundColor = BackgroundColor.White,
  isHeaderHidden = false,
  isHeaderTransparent = false,
  isFooterHidden = false,
  isFooterTransparent = false,
}: Props): JSX.Element => {
  const { isAuthenticated } = useAuthentication();
  const isVisitor = !isAuthenticated;

  return (
    <div
      className={
        'flex flex-col min-h-screen justify-between ' +
        backgroundColor.valueOf()
      }
    >
      {!isHeaderHidden && (
        <NoSSRWrapper>
          {isVisitor ? (
            <VisitorHeader isTransparent={isHeaderTransparent} />
          ) : (
            <Header isTransparent={isHeaderTransparent} />
          )}
        </NoSSRWrapper>
      )}

      <Head>
        <title>MindFeed</title>
        <meta name="description" content="MindFeed" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main>{children}</main>

      {!isFooterHidden && <Footer isTransparent={isFooterTransparent} />}
    </div>
  );
};

export const getLayout = (
  page: JSX.Element,
  backgroundColor: BackgroundColor,
  isHeaderHidden: boolean,
  isHeaderTransparent: boolean,
  isFooterHidden: boolean,
  isFooterTransparent: boolean
): JSX.Element => {
  return hasSSREnabled() ? (
    <Default
      backgroundColor={backgroundColor}
      isHeaderHidden={isHeaderHidden}
      isHeaderTransparent={isHeaderTransparent}
      isFooterHidden={isFooterHidden}
      isFooterTransparent={isFooterTransparent}
    >
      {page}
    </Default>
  ) : (
    <NoSSRWrapper>
      <Default
        backgroundColor={backgroundColor}
        isHeaderHidden={isHeaderHidden}
        isHeaderTransparent={isHeaderTransparent}
        isFooterHidden={isFooterHidden}
        isFooterTransparent={isFooterTransparent}
      >
        {page}
      </Default>
    </NoSSRWrapper>
  );
};

export default Default;
