import React from 'react';
import CollectionIcon from '../../../../public/icons/icon-collection.svg';
import classNames from 'classnames';

type Props = {
  onClick: () => void;
  className?: string;
};

const RightButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <div className={styles.container(className)}>
      <button
        type="button"
        className={styles.btn.container}
        aria-controls="mobile-menu"
        aria-expanded="false"
        onClick={onClick}
      >
        <CollectionIcon width={24} height={24} />
      </button>
    </div>
  );
};

export default RightButton;

const styles = {
  container: (className: string) => classNames('flex items-center', className),
  btn: {
    container: classNames(
      'bg-[#F4F4F4] inline-flex items-center justify-center p-1.5 rounded-full text-[#161E3A]',
      'transition-transform duration-300 hover:rotate-45'
    ),
  },
};
