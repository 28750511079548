import { useRouter } from 'next/router';
import Link from 'next/link';
import { appRoutes } from 'config';
import { reportUserClickedOnEvent } from 'utils/analytics/segment';

type Props = {
  isTransparent?: boolean;
};

const VisitorHeader = ({ isTransparent = false }: Props): JSX.Element => {
  const { pathname } = useRouter();
  const isLogin = pathname === appRoutes.LOGIN;

  return (
    <nav
      className={isTransparent ? styles.transparent.root : styles.default.root}
    >
      <div className={styles.default.container}>
        {/* Left - Logo */}
        <div className={styles.default.left}>
          <Link href={appRoutes.HOME} className={styles.default.logoHref}>
            <img
              className={styles.default.logoImg}
              src={
                isTransparent ? '/logotype-white.svg' : '/logotype-light.svg'
              }
              alt="MindFeed"
            />
          </Link>
        </div>
        {/* Right */}
        {!isLogin ? (
          <div className={styles.default.right}>
            <Link
              href={appRoutes.LOGIN}
              className={
                isTransparent
                  ? styles.transparent.ctaHref
                  : styles.default.ctaHref
              }
              onClick={() =>
                reportUserClickedOnEvent('Get Started', 'Homepage Header')
              }
            >
              Get Started
            </Link>
          </div>
        ) : null}
      </div>
    </nav>
  );
};

export default VisitorHeader;

const styles = {
  default: {
    root: 'fixed bg-white top-0 w-full z-20 border-b border-gray-200 shadow-sm',
    container:
      'max-w-7xl mx-auto flex justify-between h-16 px-4 sm:px-6 lg:px-8',
    left: 'flex',
    logoHref: 'flex items-center',
    logoImg: 'block h-8 w-auto',
    right: 'flex items-center',
    ctaHref:
      'block w-full py-2 px-5 text-center bg-white border border-mf-blue rounded-md text-base font-medium text-mf-blue hover:bg-mf-blue hover:text-white sm:inline-block sm:w-auto transition duration-200 ease-in-out',
  },
  transparent: {
    root: 'fixed bg-transparent top-0 w-full z-20 border-b border-transparent',
    ctaHref:
      'block w-full py-2 px-5 text-center bg-transparent border border-white rounded-md text-base font-medium text-white hover:bg-mf-blue hover:text-white sm:inline-block sm:w-auto transition duration-200 ease-in-out',
  },
};
